import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Waypoint } from 'react-waypoint'
import sanitizeHtml from 'sanitize-html'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import DrawerMenu from '../components/DrawerMenu'
import Cart from '../components/Cart'
import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import ProductCard from '../components/Product/ProductCard'

// Styling
const PageWrap = styled.div`
  ${tw`w-full`}
`
const CategoryWrap = styled.div`
  ${tw`w-full`}
`

const CategoryContent = styled.div`
  ${tw``}
`

const CategoryInformation = styled.div`
  ${tw`px-12 md:px-20 lg:px-12 xl:px-20 pb-12 pt-12 lg:pt-32 md:py-40 lg:order-2 lg:fixed right-0 top-0 h-full z-30 bg-colekt-light-gray overflow-y-scroll lg:align-middle text-colekt-dark-gray`}
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const CategoryImage = styled.figure`
  ${tw`h-auto`}
  background-color: #FCFCFC
`

const Products = styled.div`
  ${tw`flex flex-wrap`}
  background-color: #FCFCFC
`

const ProductCardWrap = styled.div`
  ${tw`w-full md:w-1/2 xxl:w-1/3`}
  background-color: #FCFCFC
`

const CategoryMeta = styled.div`
  ${tw``}
`

const CategoryTitle = styled.h1`
  ${tw`font-extended text-2xl md:text-3xl mb-5 tracking-wide`}
  font-size: 24px!important;
  line-height: 30px;
`

const CategoryDescription = styled.div`
  ${tw`font-mono`}
  font-size: 14px;
  letter-spacing: 0.5px;
`

// eslint-disable-next-line react/display-name
const ShopAllPage = ({ data }) => {
  const { allShopifyCollection } = data
  const { nodes } = allShopifyCollection

  // Fix for Shopify order
  const collections = nodes.reverse()

  return (
    <>
      <SEO title="Shop all" description="Shop all COLEKT products" path="all-products" />
      <Header />
      <DrawerMenu />
      <Cart />
      <PageWrap className="whiteonblack aside__padding mt-16 md:mt-0">
        <CategoryInformation className="hidden lg:flex aside__width">
          <CategoryMeta id="sidefade">
            <CategoryTitle id="title">{collections?.[0].title}</CategoryTitle>
            <CategoryDescription
              id="description"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(collections?.[0].descriptionHtml),
              }}
            />
          </CategoryMeta>
        </CategoryInformation>
        {collections?.map((collection) => (
          <CategoryWrap key={collection.id}>
            <CategoryInformation className="block lg:hidden aside__width">
              <CategoryMeta>
                <CategoryTitle>{collection.title}</CategoryTitle>
                <CategoryDescription
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(collection.descriptionHtml),
                  }}
                />
              </CategoryMeta>
            </CategoryInformation>
            <CategoryContent>
              {collection.image ? (
                <CategoryImage>
                  <GatsbyImage
                    objectFit="contain"
                    loading="lazy"
                    className="w-full"
                    imgClassName="w-full"
                    // eslint-disable-next-line prettier/prettier
                    alt={`Colekt ${collection.title}`}
                    image={getImage(collection.image.localFile)}
                  />
                </CategoryImage>
              ) : null}
              <Waypoint
                topOffset="110%"
                bottomOffset="30%"
                onLeave={() => {}}
                onEnter={() => {
                  const title = document.getElementById('title')
                  const description = document.getElementById('description')
                  if (title.innerHTML !== collection.title) {
                    document.getElementById('sidefade').classList.add('fade')
                    title.innerHTML = collection.title
                    description.innerHTML = collection.descriptionHtml
                    setTimeout(() => {
                      document.getElementById('sidefade').classList.remove('fade')
                    }, 0)
                  }
                }}
              >
                <Products>
                  {collection.products?.map((product, key) => (
                    <ProductCardWrap key={product.id}>
                      <ProductCard category={product.title} list="Collection" position={key + 1} product={product} />
                    </ProductCardWrap>
                  ))}
                </Products>
              </Waypoint>
            </CategoryContent>
          </CategoryWrap>
        ))}
        <Footer />
      </PageWrap>
    </>
  )
}

ShopAllPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ShopAllPage

export const pageQuery = graphql`
  query ShopAllQuery {
    allShopifyCollection(
      sort: { order: ASC, fields: sortOrder }
      filter: { handle: { in: ["bodycare", "skincare", "fragrance"] } }
    ) {
      nodes {
        id
        title
        descriptionHtml
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 1400, placeholder: NONE)
            }
          }
        }
        products {
          id
          title
          slug: gatsbyPath(filePath: "/product/{ShopifyProduct.handle}")
          featuredImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  layout: FULL_WIDTH
                  placeholder: NONE
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
          metafields {
            value
            key
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          metafields {
            value
            key
          }
          variants {
            sku
          }
          vendor
        }
      }
    }
  }
`
